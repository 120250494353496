import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() =>
      import("../Page/category_management/category")
    ),
  },
  // {
  //   path: "dashboard",
  //   component: asyncComponent(() => import("../dashboard")),
  // },
  {
    path: "blankPage",
    component: asyncComponent(() => import("../blankPage")),
  },
  {
    path: "change-password",
    component: asyncComponent(() =>
      import("../Page/change_password/changePassword")
    ),
  },
  // {
  //   path: "users",
  //   component: asyncComponent(() => import("../Page/users/users")),
  // },
  {
    path: "edit-profile",
    component: asyncComponent(() => import("../Page/edit_profile/editProfile")),
  },
  {
    path: "enquiries",
    component: asyncComponent(() => import("../Page/enquiries/enquiries")),
  },
  {
    path: "static-pages",
    component: asyncComponent(() => import("../Page/static_page/staticPages")),
  },
  // {
  //   path: "email-templates",
  //   component: asyncComponent(() =>
  //     import("../Page/email_template/emailTemplate")
  //   ),
  // },
  {
    path: "categories",
    component: asyncComponent(() =>
      import("../Page/category_management/category")
    ),
  },
  {
    path: "subcategory",
    component: asyncComponent(() =>
      import("../Page/subcategory_management/category")
    ),
  },
  {
    path: "checklist",
    component: asyncComponent(() =>
      import("../Page/checklist_management/category")
    ),
  },
  // {
  //   path: "business-user",
  //   component: asyncComponent(() =>
  //     import("../Page/businesses/businesses")
  //   ),
  // },
  // {
  //   path: "business-user/create",
  //   component: asyncComponent(() =>
  //     import("../Page/businesses/addUserModal")
  //   ),
  // },
  // {
  //   path: "business-user/edit/:id",
  //   component: asyncComponent(() =>
  //     import("../Page/businesses/editUserModal")
  //   ),
  // },
  // {
  //   path: "industry",
  //   component: asyncComponent(() => import("../Page/industries/industry")),
  // },
  // {
  //   path: "packages",
  //   component: asyncComponent(() => import("../Page/packages/package")),
  // },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
        {/* <Redirect to={`/404`} /> */}
      </div>
    );
  }
}

export default AppRouter;

import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
  idToken: false,
  roles: ["admin"],
  user: null,
  // token: null
});

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST: {
      // console.log("res login request requcer", action);
      return state.set("user", action.user.user);
    }
    case actions.LOGIN_SUCCESS: {
      // console.log("test LOGIN_SUCCESS reducer", action);
      if (
        action.token &&
        action.token.token &&
        action.token.token != null &&
        action.token.token != undefined
      ) {
        return state.set("idToken", action.token.token);
      } else if (action.profile && action.profile.jwt) {
        return state.set("idToken", action.profile.jwt);
      } else {
        return state.set("idToken", action.token);
      }
    }

    case actions.STORE_TOKEN: {
      // console.log("test STORE_TOKEN requcer", action);
      return state.set("idToken", action.token.token);
    }

    case actions.CHECK_ROLES:
      return state.set("roles", action.data);
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}

// case actions.LOGIN_SUCCESS:
//       if (action.user.user !== null) {
//         return state.set("user", action.user.user);
//       } else {
//         return state.set("user", action.user.user);
//       }

import { FETCH_FIREBASE_NOTIFICATIONS, SHOW_NEW_ORDER } from "../redux-web/actions/types";
import { Map } from 'immutable'
const initState = new Map({ notifications: null, notification_id: null, user: null });

const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_FIREBASE_NOTIFICATIONS:
      return state.set("notifications", action.payload);
    // case LOGIN_SUCCESS:
    //   return state.set("user", action.user).slice();
    // case LOGOUT:
    //   return initState;
    case SHOW_NEW_ORDER:
      return state.set("notification_id", action.order_id)
    default:
      return state;
  }
};
export default dataReducer;
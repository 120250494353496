const options = [
  // {
  //   key: "dashboard",
  //   label: "Dashboard",
  //   leftIcon: "fas fa-tachometer-alt",
  //   is_admin: true,
  // },
  // {
  //   key: "users",
  //   label: "Users",
  //   leftIcon: "fas fa-users",
  //   is_admin: true,
  // },
  {
    key: "categories",
    label: "Section",
    leftIcon: "fa fa-building",
    is_admin: true,
  },
  {
    key: "subcategory",
    label: "Checklist",
    leftIcon: "fa fa-building-o",
    is_admin: true,
  },
  {
    key: "checklist",
    label: "Items",
    leftIcon: "fa fa-calendar-check-o",
    is_admin: true,
  },
  // {
  //   key: "business-user",
  //   label: "Businesses",
  //   leftIcon: "fa fa-users",
  //   is_admin: true,
  // },
  // {
  //   key: "industry",
  //   label: "Industries",
  //   leftIcon: "fa fa-building",
  //   is_admin: true,
  // },
  // {
  //   key: "packages",
  //   label: "Package Management",
  //   leftIcon: "fas fa-sticky-note",
  //   is_admin: true,
  // },
  {
    key: "static-pages",
    label: "Static pages",
    leftIcon: "fa fa-file-text",
    is_admin: true,
  },
  {
    key: "enquiries",
    label: "Enquiry Management",
    leftIcon: "fa fa-commenting",
    is_admin: true,
  },
  // {
  //   key: "email-templates",
  //   label: "Email Templates",
  //   leftIcon: "fas fa-sticky-note",
  //   is_admin: true,
  // },
];
export default options;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";

import authAction from "../../redux/auth/actions";

// import Notification from "../Page/sub-admin/src/components/notification/notification";

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_admin: null,
      userdata: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      is_admin: this.props.user ? this.props.user.is_admin : false,
      userdata: this.props.user
    });
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps !== this.props) {
      if (nextProps.user && nextProps.user != null) {
        this.setState({
          is_admin: nextProps.user.is_admin,
          userdata: nextProps.user
        });
      }
    }
  };

  render() {
    const { toggleCollapsed } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
    return (
      <TopbarWrapper>
        {/* notification here */}
        {/* {
          !this.state.is_admin &&
          <Notification />
        } */}

        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >

          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          {!this.state.is_admin && this.state.userdata &&
            <div class="top-shop-head">
              {/* <h2 className="banner-head-left"> */}
                {/* <img src={this.state.userdata.image}></img> */}
                {/* <span className="zone font-white">Shop</span> */}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {/* <span className="rest-name font-white">{this.state.userdata.name}</span>
                <span className="seperator font-white"> at </span>
                <span className="zone font-white">{this.state.userdata.mall}</span>
              // </h2> */}
            </div>
          }

          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => {
    return ({
      ...state.App.toJS(),
      user: state.Auth.get("user"),
      token: state.Auth.get("idToken")
    })
  },
  { toggleCollapsed }
)(Topbar);
